<template>
  <div>
    <h2><strong>我的收藏</strong></h2>

    <me-tabs v-model="tabIndex" :tabs="tabs" @change="tabChange"></me-tabs>
    <div class="ant-table-wrapper" :class="loading?'loading':''">

      <div v-if="loading"  class="ant-spin-nested-loading ant-table-with-pagination ant-table-spin-holder"><div><div class="ant-spin ant-spin-spinning"><span class="ant-spin-dot ant-spin-dot-spin"><i class="ant-spin-dot-item"></i><i class="ant-spin-dot-item"></i><i class="ant-spin-dot-item"></i><i class="ant-spin-dot-item"></i></span></div></div></div>
      <waterfall
        :col="6"
        :width="itemWidth"
        :gutterWidth="gutterWidth"
        :data="couponlist"

      >
        <template>
          <div
            class="cell-item"
            v-for="(item, index) in couponlist"
            :key="index"
          >
            <product-item
              :name="item.tname"
              :price="item.unitprice"
              :usenumber="item.nowcosttimes"
              :labelname="item.tlname"
              :tavatar="item.tavatar"
              :tcode="item.tcode"
              :ossurl="ossUrl"
              :tfids="item.tfids.split(',')"
              :prodid="item.prodid"
              :psid="item.psid"
            />
          </div>
        </template>
      </waterfall>

      <!-- <div class="itemWrap">

                <a-list  item-layout="horizontal" :loading='loading' :data-source="couponlist" :pagination="{ ...paginationProps, current: currentPage }">
                <a-list-item slot="renderItem" slot-scope="item">
                    <product-item
                        :name="item.tname"
                        :price="item.unitprice"
                        :usenumber="item.nowcosttimes"
                        :labelname="item.tlname"
                        :tavatar="item.tavatar"
                        :ossurl="ossUrl"
                    />
                </a-list-item>
            </a-list>
            </div> -->
    </div>
    <a-pagination
      v-model="currentPage"
      :hideOnSinglePage="true"
      :total="total"
      :pageSize="pageSize"
      @change="changeData"
      style="float: right"
    />
  </div>
</template>
<script>
import ProductItem from "@/components/ProductItem";
import meTabs from "@/components/meTabs";
import { getFavlist } from "@/api/manage";
import { mapGetters } from "vuex";
export default {
  components: {
    meTabs,
    ProductItem,
  },
  data() {
    return {
      tabs: [
        { name: "全部", istype: 0 },
        { name: "海报", istype: 1 },
        { name: "H5", istype: 2 },
        { name: "表单", istype: 3 },
      ],
      tabIndex: 0,
      couponlist: [],
      currentPage: 1, // 当前页
      total: 0,
      pageSize: 9,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["ossUrl"]),
    itemWidth() {
      return 260;
    },

    gutterWidth() {
      return 10;
    },

    // paginationProps() {
    //   const _this = this;
    //   return {
    //     // showQuickJumper: true,
    //     pageSize: _this.pageSize,
    //     total: _this.total,
    //     onChange(page, pageSize) {
    //       console.log(page, pageSize);
    //       _this.loading = true;
    //       _this.currentPage = page;
    //       _this.tabChange();
    //     },
    //   };
    // },
  },

  mounted() {
    this.getFavlistData();
  },
  methods: {
    changeData(page, pageSize) {
      console.log(page, pageSize);
      this.currentPage = page;
      this.getFavlistData();
    },
    tabChange() {

      this.currentPage=1;
      this.getFavlistData()

    },
    getFavlistData(){
      this.loading = true;
      getFavlist({
        pageSize: this.pageSize,
        pageNo: this.currentPage,
        prodid: this.tabIndex,
      }).then(({ data }) => {
        let _this=this
        setTimeout(function(){ _this.loading = false;},400)

        this.total = parseInt(data.total);
        this.couponlist = data.data;
        console.log(data);
      });
    }
  },
};
</script>
<style lang="less" scoped>
.cell-item {
  margin: 10% 0;
}
.loading{
  position: relative;
  &::after{
    content:'';
    position: absolute;
    // background: #0049ee;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.6);


  }

}
 .ant-spin{
   height: 100vh!important;
  //  background: #fff;
 }
</style>
<style lang="less" scoped>
h2{
    border-bottom: 1px solid #ddd;
    padding-bottom:20px;
    strong{
        position: relative;
        &:after {
            content: '';
            position: absolute;
            height: 3px;
            width: 120px;
            left: 0;
            bottom: -25px;
            background-image: linear-gradient(to right, #0049ee , #36bcf0);

        }
    }
}
.business-nav-box{
  margin-top: 40px;
}
</style>
<style lang="less" scoped>
/* .itemWrap{
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
} */
/deep/.ant-list-items {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  flex-direction: row;
  flex-wrap: wrap;
}
.ant-list-item {
  width: 200px;
  margin: 14px;
  border: none !important;
}
</style>